<template>
  <div>
    <h1 :class="$style.pageTitle">運行計画表</h1>
    <div class="row">
      <div class="col-lg-12">
        <section class="card">
          <div class="card-body">
            <div :class="$style.targetDescription">
              <div :class="$style.targetItem">
                <span :class="$style.targetLabel">対象日</span>
                <span :class="$style.targetValue">{{ gotDate | momentDate }}</span>
              </div>
              <div :class="$style.targetItem">
                <span :class="$style.targetLabel">対象ユーザー</span>
                <span :class="$style.targetValue">{{ userName }}</span>
              </div>
            </div>
            <hr>
            <div :class="$style.targetDescription">
              <div :class="$style.targetItem">
                <span :class="$style.dateBtnContainer">
                  <a-button @click="onDateBtnClick(-1)">前日</a-button>
                  <a-button @click="onDateBtnClick(0)">今日</a-button>
                  <a-button @click="onDateBtnClick(1)">翌日</a-button>
                </span>
                <a-config-provider :locale="locale">
                  <a-date-picker placeholder="対象日" v-model="targetDate" :class="$style.datePicker"/>
                </a-config-provider>
              </div>
              <div :class="$style.targetItem">
                <span :class="$style.targetLabel">ドライブリスト</span>
                <router-link :to="{ name: 'driveList' }">
                  <a-button style="width: 80px;">一覧</a-button>
                </router-link>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="col-lg-12">
        <section class="card">
          <div v-if="!loading" class="card-header" :class="$style.cardHeader">
            <h2>運行予定</h2>
            <div :class="$style.endPoints">
              <span>
                【出発予定】
                <span v-if="endPointAtStart != null">
                  {{ endPointAtStart.point_date_time | momentTime }}<template v-if="!!endPointAtStart.name">、{{ endPointAtStart.name }}</template>
                </span>
                <span v-else>未設定</span>
              </span>
              <br/>
              <span>
                【帰着予定】
                <span v-if="endPointAtEnd != null">
                  {{ endPointAtEnd.point_date_time | momentTime }}<template v-if="!!endPointAtEnd.name">、{{ endPointAtEnd.name }}</template>
                </span>
                <span v-else>未設定</span>
              </span>
            </div>
            <div :class="$style.buttons">
              <a-button type="primary" @click="openEndPointModal('start')" :class="$style.filledBtn">
                <template v-if="endPointAtStart == null">出発登録</template>
                <template v-else>出発更新</template>
              </a-button>
              <a-button type="primary" @click="openEndPointModal('end')" :class="$style.filledBtn">
                <template v-if="endPointAtEnd == null">終点登録</template>
                <template v-else>終点更新</template>
              </a-button>
              <a-button type="primary" @click="openScheduleModal()" :class="$style.filledBtn">
                行先登録
              </a-button>
            </div>
          </div>
          <div class="card-body">
            <div v-if="loading" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <div v-else>
              <a-list
                item-layout="horizontal"
                :data-source="sortedScheduleList"
                :pagination="true"
              >
                <a-list-item slot="renderItem" slot-scope="item" :class="$style.scheduleItem">
                  <div :class="$style.scheduleNameContainer">
                    <a-avatar :size="56" icon="calendar" :class="$style.icon"/>
                    <span :class="$style.name">{{ item.name }}</span>
                  </div>
                  <div :class="$style.scheduleInfoContainer">
                    <div :class="$style.scheduleInfoItem">
                      <span :class="$style.infoLabel">時間</span>
                      <span :class="$style.infoValue">{{ item.date_time_start | momentTime }}〜{{ item.date_time_end | momentTime }}</span>
                    </div>
                    <div :class="$style.scheduleInfoItem">
                      <span :class="$style.infoLabel">目的</span>
                      <span :class="$style.infoValue">{{ item.purpose }}</span>
                    </div>
                    <div :class="$style.scheduleInfoItem">
                      <span :class="$style.infoLabel">住所</span>
                      <span :class="$style.infoValue">{{ item.address }}</span>
                    </div>
                  </div>
                  <div :class="$style.editBtnContainer">
                    <a-button @click="openScheduleModal(item)">編集</a-button>
                  </div>
                </a-list-item>
              </a-list>
            </div>
          </div>
        </section>
      </div>
    </div>

    <!-- 出発/終点 作成/編集モーダル-->
    <a-modal
      :title="`${endPointTypeLabel}${endPointModalText}`"
      :visible="endPointModalVisible"
      @cancel="handleCancelEndPointModal"
    >
      <div>
        <a-form-model
          ref="endPointFormRef"
          :rules="endPointRules"
          :model="endPointForm"
        >
          <div v-if="endPointForm.default_applied" class="mb-2">
            ※デフォルトのデータがセットされました。
          </div>

          <a-form-model-item label="日時" prop="point_date_time">
            <a-config-provider :locale="locale">
              <a-date-picker v-model="endPointForm.date" @change="setEndPointDefaultAppliedFalse" />
            </a-config-provider>
            <a-config-provider :locale="locale">
              <a-time-picker v-model="endPointForm.time" @change="setEndPointDefaultAppliedFalse" format="HH:mm" class="ml-1" />
            </a-config-provider>
          </a-form-model-item>
          <a-form-model-item v-if="driveList.length > 0" label="ドライブリストから選択"
              extra="選択した項目の名前と住所が設定されます。">
            <a-select
              show-search
              v-model="endPointForm.drive_list_id"
              :filter-option="filterOption"
              @change="driveListItemChangeOnEndPointModal"
            >
              <a-select-option :key="0" :value="0">未選択</a-select-option>
              <a-select-option
                v-for="item in driveList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}: {{ item.address }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="名前" prop="name">
            <a-input v-model="endPointForm.name" @change="setEndPointDefaultAppliedFalse" />
          </a-form-model-item>
          <a-form-model-item label="住所" prop="address">
            <a-input v-model="endPointForm.address" @change="setEndPointDefaultAppliedFalse" />
          </a-form-model-item>
          <div>
            <a-checkbox v-model="endPointForm.default_flag" @change="setEndPointDefaultAppliedFalse">
              この{{ endPointTypeLabel }}情報をデフォルトにする
            </a-checkbox>
          </div>
        </a-form-model>
      </div>
      <template slot="footer">
        <div class="clearfix">
          <div class="float-left">
            <a-button v-if="targetEndPointId" key="submit" type="danger" :loading="confirmLoading" @click="handleDeleteEndPoint">
              削除
            </a-button>
          </div>
          <div class="float-right">
            <a-button key="back" @click="handleCancelEndPointModal">
              キャンセル
            </a-button>
            <a-button key="submit" type="primary" :loading="confirmLoading" @click="handleOkEndPoint" :class="$style.filledBtn">
              {{ endPointModalText }}
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>

    <!-- 行先 作成/編集モーダル-->
    <a-modal
      :title="`行先${scheduleModalText}`"
      :visible="scheduleModalVisible"
      @cancel="handleCancelScheduleModal"
    >
      <div>
        <a-form-model
          ref="scheduleFormRef"
          :rules="scheduleRules"
          :model="scheduleForm"
        >
          <a-form-model-item label="開始日時" prop="date_time_start">
            <a-config-provider :locale="locale">
              <a-date-picker v-model="scheduleForm.date_start" />
            </a-config-provider>
            <a-config-provider :locale="locale">
              <a-time-picker v-model="scheduleForm.time_start" format="HH:mm" class="ml-1" />
            </a-config-provider>
          </a-form-model-item>
          <a-form-model-item label="終了日時" prop="date_time_end">
            <a-config-provider :locale="locale">
              <a-date-picker v-model="scheduleForm.date_end" />
            </a-config-provider>
            <a-config-provider :locale="locale">
              <a-time-picker v-model="scheduleForm.time_end" format="HH:mm" class="ml-1" />
            </a-config-provider>
          </a-form-model-item>
          <a-form-model-item v-if="driveList.length > 0" label="ドライブリストから選択"
              extra="選択した項目の名前と住所が設定されます。">
            <a-select
              show-search
              v-model="scheduleForm.drive_list_id"
              :filter-option="filterOption"
              @change="driveListItemChangeOnScheduleModal"
            >
              <a-select-option :key="0" :value="0">未選択</a-select-option>
              <a-select-option
                v-for="item in driveList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}: {{ item.address }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="名前" prop="name">
            <a-input v-model="scheduleForm.name" />
          </a-form-model-item>
          <a-form-model-item label="住所" prop="address">
            <a-input v-model="scheduleForm.address" />
          </a-form-model-item>
          <a-form-model-item label="目的" prop="purpose">
            <a-select
              show-search
              v-model="scheduleForm.purpose_id"
              :filter-option="filterOption"
            >
              <a-select-option :key="0" :value="0">未選択</a-select-option>
              <a-select-option
                v-for="item in drivingPurposeList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="説明" prop="description">
            <a-textarea v-model="scheduleForm.description" :rows="3"/>
          </a-form-model-item>
        </a-form-model>
      </div>
      <template slot="footer">
        <div class="clearfix">
          <div class="float-left">
            <a-button v-if="targetScheduleId" key="submit" type="danger" :loading="confirmLoading" @click="handleDeleteSchedule">
              削除
            </a-button>
          </div>
          <div class="float-right">
            <a-button key="back" @click="handleCancelScheduleModal">
              キャンセル
            </a-button>
            <a-button key="submit" type="primary" :loading="confirmLoading" @click="handleOkSchedule" :class="$style.filledBtn">
              {{ scheduleModalText }}
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<style lang="scss" module>
  @import './style.module.scss';
</style>
<script>
import Vue from 'vue'
import moment from 'moment'
import jaJa from 'ant-design-vue/es/locale/ja_JP'

export default {
  props: [
    'targetUserId',
    'targetUserName',
  ],
  data() {
    const requiredRule = { required: true, message: '必須項目です。', trigger: 'change' }
    const maxTextRule = { max: 1024, message: '1024文字までです。', trigger: 'change' }

    return {
      locale: jaJa,
      targetDate: moment(),
      gotDate: moment(),
      loading: true,
      userId: '',
      userName: '',
      driveList: [], // ログインユーザーのドライブリスト
      drivingPurposeList: [], // 会社の目的リスト
      scheduleList: [], // 行先リスト
      endPointAtStart: null, // 登録済みの選択日の出発点
      endPointAtEnd: null, // 登録済みの選択日の終点
      defaultEndPointAtStart: null, // 登録済みのデフォルト出発点
      defaultEndPointAtEnd: null, // 登録済みのデフォルト終点
      confirmLoading: false,

      // 出発/終点モーダル
      endPointModalVisible: false,
      endPointType: 'start',
      targetEndPointId: 0,
      endPointForm: {
        point_date_time: moment(),
        date: moment(),
        time: moment(),
        name: '',
        address: '',
        drive_list_id: 0,
        default_flag: false,
        default_applied: false,
      },
      endPointRules: {
        point_date_time: [requiredRule],
        name: [maxTextRule],
        address: [maxTextRule],
      },

      // 行先モーダル
      scheduleModalVisible: false,
      targetScheduleId: 0,
      scheduleForm: {
        date_time_start: moment(),
        date_start: moment(),
        time_start: moment(),
        date_time_end: moment(),
        date_end: moment(),
        time_end: moment(),
        name: '',
        address: '',
        purpose_id: 0,
        description: '',
        drive_list_id: 0,
      },
      scheduleRules: {
        date_time_start: [requiredRule],
        date_time_end: [requiredRule],
        name: [maxTextRule],
        address: [maxTextRule],
      },
    }
  },
  computed: {
    endPointTypeLabel() { return this.endPointType === 'start' ? '出発' : '終点' },
    endPointModalText() { return this.targetEndPointId === 0 ? '登録' : '更新' },
    scheduleModalText() { return this.targetScheduleId === 0 ? '登録' : '更新' },
    sortedScheduleList() {
      return this.scheduleList.concat().sort((a, b) => {
        const startA = moment(a.date_time_start)
        const startB = moment(b.date_time_start)
        if (startA.isAfter(startB)) return 1
        if (startB.isAfter(startA)) return -1
        const endA = moment(a.date_time_end)
        const endB = moment(b.date_time_end)
        if (endA.isAfter(endB)) return 1
        if (endB.isAfter(endA)) return -1
        return 0
      })
    },
  },
  created() {
    if (this.targetUserId != null) {
      this.userId = Number(this.targetUserId)
      this.userName = this.targetUserName
      this.getDefaultEndPoints()
      this.refresh()
    } else {
      Vue.prototype.$api.send('get', 'user')
        .then(response => {
          this.userId = response.id
          this.userName = response.name
          this.getDefaultEndPoints()
          this.refresh()
        })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': ユーザーの取得に失敗しました。',
          })
        })
    }

    Vue.prototype.$api.send('get', 'drive_lists')
      .then(response => {
        this.driveList = response
      })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ':ドライブリストの取得に失敗しました。',
        })
      })

    Vue.prototype.$api.send('get', 'driving_purposes')
      .then(response => {
        this.drivingPurposeList = response
      })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ':目的リストの取得に失敗しました。',
        })
      })
  },
  watch: {
    targetDate() {
      this.refresh()
    },
  },
  filters: {
    momentDate: (arg) => arg ? moment(arg).format('YYYY年MM月DD日') : '',
    momentTime: (arg) => arg ? moment(arg).format('HH:mm') : '',
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    onDateBtnClick(offset) {
      if (offset === 0) {
        this.targetDate = moment()
      } else if (offset === 1) {
        this.targetDate = moment(this.targetDate).add(1, 'days')
      } else if (offset === -1) {
        this.targetDate = moment(this.targetDate).add(-1, 'days')
      }
    },
    // デフォルトエンドポイントを取得する。
    getDefaultEndPoints() {
      Vue.prototype.$api.send('get', 'driving_schedules/endpoints/default', { user_id: this.userId })
        .then(response => {
          this.defaultEndPointAtStart = response.data.find(item => item.type === 0)
          this.defaultEndPointAtEnd = response.data.find(item => item.type === 1)
        })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ':デフォルトエンドポイントの取得に失敗しました。',
          })
        })
    },
    // 選択日の情報を取得する
    refresh() {
      this.loading = true

      const p1 = this.getEndpoints()
      const p2 = Vue.prototype.$api.send(
        'get', 'driving_schedules',
        {
          user_id: this.userId,
          date: this.targetDate.format('YYYY-MM-DD'),
        },
      )
        .then(response => {
          this.scheduleList = response.data
        })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ':行先情報の取得に失敗しました。',
          })
        })

      return Promise.all([p1, p2])
        .then(() => {
          this.gotDate = this.targetDate.clone()
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 出発・終点の情報を取得する
    getEndpoints() {
      const bodyData = {
        user_id: this.userId,
        date: this.targetDate.format('YYYY-MM-DD'),
      }
      return Vue.prototype.$api.send('get', 'driving_schedules/endpoints', bodyData)
        .then(response => {
          this.endPointAtStart = response.data.find(item => item.type === 0)
          this.endPointAtEnd = response.data.find(item => item.type === 1)
        })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ':出発・終点情報の取得に失敗しました。',
          })
        })
    },
    // 出発/終点モーダルを開く
    openEndPointModal(type) {
      this.clearEndPointValidate()

      // 選択日の登録済みまたはデフォルトのデータを適用する
      const buildForm = (thisData, defaultData) => {
        const data = thisData || defaultData || {}
        const date = thisData ? moment(thisData.point_date_time) : this.gotDate.clone()
        const time = thisData ? moment(thisData.point_date_time) : this.gotDate.clone().startOf('day')
        this.endPointForm = {
          point_date_time: this.mergeDateTime(date, time),
          date: date,
          time: time,
          name: data.name || '',
          address: data.address || '',
          drive_list_id: 0,
          default_flag: false,
          default_applied: !thisData && !!defaultData,
        }
        this.targetEndPointId = thisData ? thisData.id : 0
      }

      if (type === 'start') {
        buildForm(this.endPointAtStart, this.defaultEndPointAtStart)
      } else {
        buildForm(this.endPointAtEnd, this.defaultEndPointAtEnd)
      }

      this.endPointType = type
      this.endPointModalVisible = true
    },
    // 出発/終点モーダルのドライブリスト選択時
    driveListItemChangeOnEndPointModal() {
      const item = this.driveList.find(e => e.id === this.endPointForm.drive_list_id)
      if (item) {
        this.endPointForm.name = item.name
        this.endPointForm.address = item.address
        this.clearEndPointValidate(['name', 'address'])
        this.setEndPointDefaultAppliedFalse()
      }
    },
    handleCancelEndPointModal() { this.endPointModalVisible = false },
    // 出発/終点の削除
    handleDeleteEndPoint() {
      Vue.prototype.$api.send(
        'delete',
        'driving_schedules/endpoints',
        { id: this.targetEndPointId },
      )
        .then(_response => {
          this.$notification['success']({
            message: `${this.endPointTypeLabel}を削除しました。`,
          })

          if (this.endPointAtStart && this.endPointAtStart.id === this.targetEndPointId) {
            this.endPointAtStart = null
          }
          if (this.endPointAtEnd && this.endPointAtEnd.id === this.targetEndPointId) {
            this.endPointAtEnd = null
          }
          if (this.defaultEndPointAtStart && this.defaultEndPointAtStart.id === this.targetEndPointId) {
            this.defaultEndPointAtStart = null
          }
          if (this.defaultEndPointAtEnd && this.defaultEndPointAtEnd.id === this.targetEndPointId) {
            this.defaultEndPointAtEnd = null
          }
          this.endPointModalVisible = false
        })
        .catch(error => {
          if (error.status === 403) {
            this.$notification['error']({
              message: error.data.data,
            })
          } else if (error.data && error.data.errors) {
            for (var key in error.data.errors) {
              this.$refs[key].validateState = 'error'
              this.$refs[key].validateMessage = error.data.errors[key]
            }
          } else {
            this.$notification['error']({
              message: `${this.endPointTypeLabel}の削除に失敗しました。`,
            })
          }
          console.log(error)
        })
        .finally(() => {
          this.confirmLoading = false
        })
    },
    // 出発/終点の登録/更新
    handleOkEndPoint() {
      this.endPointForm.point_date_time =
        this.mergeDateTime(this.endPointForm.date, this.endPointForm.time)

      this.$refs.endPointFormRef.validate(valid => {
        if (!valid) return

        let actionType = 'post'
        let actionUrl = `driving_schedules/endpoints/${this.endPointType}`
        const bodyData = {
          user_id: this.userId,
          default_flag: this.endPointForm.default_flag ? 1 : 0,
          point_date_time: this.endPointForm.point_date_time.format('YYYY-MM-DD HH:mm:ss'),
          name: this.endPointForm.name,
          address: this.endPointForm.address,
        }

        if (this.targetEndPointId !== 0) {
          actionType = 'put'
          actionUrl = 'driving_schedules/endpoints'
          bodyData.endpoint_id = this.targetEndPointId
        }

        Vue.prototype.$api.send(
          actionType,
          actionUrl,
          bodyData,
        )
          .then(response => {
            this.$notification['success']({
              message: `${this.endPointTypeLabel}を${this.endPointModalText}しました。`,
            })

            if (response.default_flag === 1) {
              if (this.endPointType === 'start') {
                this.defaultEndPointAtStart = response
              } else {
                this.defaultEndPointAtEnd = response
              }
            }
            if (this.gotDate.isSame(response.point_date_time, 'day')) {
              if (this.endPointType === 'start') {
                this.endPointAtStart = response
              } else {
                this.endPointAtEnd = response
              }
            }
            this.endPointModalVisible = false
          })
          .catch(error => {
            if (error.status === 403 || error.status === 400 || error.status === 409) {
              this.$notification['error']({
                message: error.data.data,
              })
            } else if (error.data && error.data.errors) {
              for (var key in error.data.errors) {
                this.$refs[key].validateState = 'error'
                this.$refs[key].validateMessage = error.data.errors[key]
              }
            } else {
              this.$notification['error']({
                message: `${this.endPointTypeLabel}の${this.endPointModalText}に失敗しました。`,
              })
            }
            console.log(error)
          })
          .finally(() => {
            this.confirmLoading = false
          })
      })
    },
    // 出発/終点モーダルのバリデーションエラーをクリア
    clearEndPointValidate(nameList) {
      try {
        this.$refs.endPointFormRef.clearValidate(nameList)
      } catch (_error) {}
    },
    setEndPointDefaultAppliedFalse() {
      this.endPointForm.default_applied = false
    },
    // 行先モーダルを開く
    openScheduleModal(data) {
      this.clearScheduleValidate()

      const dateTimeStart = data ? moment(data.date_time_start) : this.targetDate.clone().startOf('day')
      const dateTimeEnd = data ? moment(data.date_time_end) : dateTimeStart.clone()
      const purpose = data ? this.drivingPurposeList.find(item => item.name === data.purpose) : null

      this.scheduleForm = {
        date_time_start: dateTimeStart,
        date_start: dateTimeStart.clone(),
        time_start: dateTimeStart.clone(),
        date_time_end: dateTimeEnd,
        date_end: dateTimeEnd.clone(),
        time_end: dateTimeEnd.clone(),
        name: data ? data.name : '',
        address: data ? data.address : '',
        purpose_id: (purpose && purpose.id) || 0,
        description: data ? data.description : '',
        drive_list_id: 0,
      }
      this.targetScheduleId = data ? data.id : 0
      this.scheduleModalVisible = true
    },
    // 行先モーダルのドライブリスト選択時
    driveListItemChangeOnScheduleModal() {
      const item = this.driveList.find(e => e.id === this.scheduleForm.drive_list_id)
      if (item) {
        this.scheduleForm.name = item.name
        this.scheduleForm.address = item.address
        this.clearScheduleValidate(['name', 'address'])
      }
    },
    handleCancelScheduleModal() { this.scheduleModalVisible = false },
    // 出発/終点の削除
    handleDeleteSchedule() {
      Vue.prototype.$api.send(
        'delete',
        'driving_schedules',
        { id: this.targetScheduleId },
      )
        .then(_response => {
          this.$notification['success']({
            message: `行先を削除しました。`,
          })

          this.scheduleList = this.scheduleList.filter(item => item.id !== this.targetScheduleId)
          this.scheduleModalVisible = false
        })
        .catch(error => {
          if (error.status === 403) {
            this.$notification['error']({
              message: error.data.data,
            })
          } else if (error.data && error.data.errors) {
            for (var key in error.data.errors) {
              this.$refs[key].validateState = 'error'
              this.$refs[key].validateMessage = error.data.errors[key]
            }
          } else {
            this.$notification['error']({
              message: `行先の削除に失敗しました。`,
            })
          }
          console.log(error)
        })
        .finally(() => {
          this.confirmLoading = false
        })
    },
    // 行先の登録/更新
    handleOkSchedule() {
      this.scheduleForm.date_time_start =
        this.mergeDateTime(this.scheduleForm.date_start, this.scheduleForm.time_start)
      this.scheduleForm.date_time_end =
        this.mergeDateTime(this.scheduleForm.date_end, this.scheduleForm.time_end)

      this.$refs.scheduleFormRef.validate(valid => {
        if (!valid) return

        const purpose = this.drivingPurposeList.find(item => item.id === this.scheduleForm.purpose_id)

        let actionType = 'post'
        const bodyData = {
          user_id: this.userId,
          date_time_start: this.scheduleForm.date_time_start.format('YYYY-MM-DD HH:mm:ss'),
          date_time_end: this.scheduleForm.date_time_end.format('YYYY-MM-DD HH:mm:ss'),
          name: this.scheduleForm.name,
          address: this.scheduleForm.address,
          purpose: (purpose && purpose.name) || '',
          description: this.scheduleForm.description,
        }

        if (this.targetScheduleId !== 0) {
          actionType = 'put'
          bodyData.id = this.targetScheduleId
        }

        Vue.prototype.$api.send(
          actionType,
          `driving_schedules`,
          bodyData,
        )
          .then(response => {
            this.$notification['success']({
              message: `行先を${this.scheduleModalText}しました。`,
            })

            this.scheduleList = this.scheduleList.filter(item => item.id !== this.targetScheduleId)
            if (moment(response.date).isSame(this.gotDate, 'day')) {
              this.scheduleList.push(response)
            }

            this.scheduleModalVisible = false
          })
          .catch(error => {
            if (error.status === 403 || error.status === 400) {
              this.$notification['error']({
                message: error.data.data,
              })
            } else if (error.data && error.data.errors) {
              for (var key in error.data.errors) {
                this.$refs[key].validateState = 'error'
                this.$refs[key].validateMessage = error.data.errors[key]
              }
            } else {
              this.$notification['error']({
                message: `行先の${this.scheduleModalText}に失敗しました。`,
              })
            }
            console.log(error)
          })
          .finally(() => {
            this.confirmLoading = false
          })
      })
    },
    // 行き先モーダルのバリデーションエラーをクリア
    clearScheduleValidate(nameList) {
      try {
        this.$refs.scheduleFormRef.clearValidate(nameList)
      } catch (_error) {}
    },
    mergeDateTime(date, time) {
      if (date && time) {
        return moment(date.format('YYYY-MM-DD') + ' ' + time.format('HH:mm'))
      }
      return null
    },
  },
}
</script>
